import React from "react";

const DecorationToBottom = () => {
  return (
    <svg width="435px" height="1762px" viewBox="0 0 435 1762" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-111.000000, -70.000000)" stroke="#184179">
          <polyline
            className={"line"}
            points="16 32 322.233087 224.144036 322.233087 1667.96889 988 2073"
          />
          <polyline
            className={"line"}
            points="0 0 306.233087 192.238178 306.233087 1636.77044 972 2042"
          />
          <polyline
            className={"line"}
            points="33 62 339.233087 254.238178 339.233087 1698.77044 1005 2104"
          />
        </g>
      </g>
    </svg>
  );
};

export default DecorationToBottom;
