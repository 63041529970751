import React from "react";
import styled from "styled-components";
import { white } from "./styles/Colors";
import { mediaDesktop, mediaMedium } from "./styles/variables";
import Container from "./styles/Container";

const Wrapper = styled.div`
  background-color: ${(props) => props.background ?? white};
  overflow: hidden;
  position: relative;
  padding: ${(props) => props.paddingMedia ?? 0};
  ${(props) => (props.soloSpace ? props.soloSpace : "")}

  @media (${mediaMedium}) {
    padding: ${(props) => props.padding ?? 0};
  }
`;

const Grid = styled(Container)`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: ${(props) => props.desktopGap ?? "18px"};
  padding: 0;
  position: relative;
  justify-content: ${(props) => props.justify ?? "space-between"};

  ${(props) => (props.reverse ? `&>*:first-child {order:3}` : "")}
  ${(props) =>
    props.reverse && !props.noPadding
      ? `padding-top:88px}`
      : ""}


  @media (${mediaMedium}) {
    grid-gap: ${(props) => props.gap ?? "24px"};
    ${(props) => (props.reverse ? `&>*:first-child {order:0}` : "")}
    grid-template-columns: ${(props) => props.columns ?? "50% 50%"};
    ${(props) => `align-items: ${props.align};` ?? ""};
  };

  @media (${mediaDesktop}) {
    ${(props) =>
      props.columnsLarge ? `grid-template-columns:${props.columnsLarge};` : ""};
  };
`;

const HeroTwoColumns = ({
  columns,
  columnsLarge,
  align,
  background,
  decoration,
  reverse,
  soloSpace,
  noPadding,
  children,
  justify,
  padding,
  paddingMedia,
  gap,
  desktopGap
}) => {
  const Element = decoration ?? "";

  return (
    <Wrapper
      paddingMedia={paddingMedia}
      soloSpace={soloSpace}
      padding={padding}
      background={background}
    >
      <Grid
        align={align}
        justify={justify}
        noPadding={noPadding}
        reverse={reverse}
        columns={columns}
        columnsLarge={columnsLarge}
        gag={gap}
        desktopGap={desktopGap}
      >
        {children}
      </Grid>
      {decoration && Element}
    </Wrapper>
  );
};

export default HeroTwoColumns;
