import React from "react";
import styled from "styled-components";
import TitleWithTrail from "./TitleWithTrail";
import { Body24 } from "./styles/BodyTypes";
import ArrowButton from "./ArrowButton";
import { mediaMedium } from "./styles/variables";
import { black1 } from "./styles/Colors";

const Wrapper = styled.div`
  ${(props) =>
    props.noTrail && !!props.paddingMedia
      ? `padding: ${props.paddingMedia};`
      : ""};
  @media (${mediaMedium}) {
    ${(props) =>
      props.noTrail && !!props.noPadding ? `padding-top: 112px;` : ""};
    padding-bottom: 122px;
    ${(props) => (props.padding ? `padding: ${props.padding};` : "")};
  }
`;

const Row = styled.span`
  display: block;
  margin-top: 24px;
`;

const Content = styled.div`
  margin-top: 30px;
  
  @media (${mediaMedium}) {
    margin: 0;
  }
`;

const HeroContentSnippet = ({
  color,
  title,
  subTitle = undefined,
  link,
  label,
  noTrail,
  noPadding,
  children,
  marginTitle,
  padding,
  noBreak,
  paddingMedia,
  buttonVariant = undefined
}) => {
  return (
    <Wrapper noTrail={noTrail} padding={padding} paddingMedia={paddingMedia}>
      <TitleWithTrail
        noTrail={noTrail}
        noPadding={noPadding}
        color={color}
        title={title}
        subTitle={subTitle}
        marginTitle={marginTitle}
        noBreak={noBreak}
      />
      <Content>
        <Body24 as="div" theme={{ color: color ?? black1 }}>
          {children}
          {link && (
            <Row>
              <ArrowButton variant={buttonVariant} large={true} attrs={link}>
                {label ? label : "Find Out More"}
              </ArrowButton>
            </Row>
          )}
        </Body24>
      </Content>
    </Wrapper>
  );
};

export default HeroContentSnippet;
