import React from "react";
import styled from "styled-components";
import Image from "./Image";
import { mediaMedium } from "./styles/variables";
import FadeInDiv from "./FadeInDiv";

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  pointer-events: none;
  display: grid;
  grid-template-columns: 100%;
`;

const Decoration = styled.div`
  display: flex;
  align-items: flex-end;

  @media (${mediaMedium}) {
    position: absolute;
    height: 100%;
    width: ${(props) => props.theme.width ?? "calc(200% + (100% * 3/12))"};
    transform: ${(props) =>
      props.theme.transform ?? "translateX(calc(-100% * 1 / 12 - 20px))"};
    top: ${(props) => props.theme.top ?? "0"};
    left: ${(props) => props.theme.left ?? "0"};
  }
`;

const Display = styled(Image)`
  user-select: none;
  display: none;
  ${(props) => props.theme.css ?? ""}

  &.gatsby-image-wrapper {
    width: 100%;

    > img {
      height: 100% !important;
    }
  }

  @media (${mediaMedium}) {
    display: block;
  }
`;

const Mobile = styled(Image)`
  user-select: none;
  width: 100%;
  ${(props) => props.theme.mediaCss ?? ""}

  @media (${mediaMedium}) {
    display: none;
  }
`;

const Div = styled.div``;

const DisplayWithOverflow = ({ image, theme, animation }) => {
  return (
    <Wrapper>
      <Div as={animation ? FadeInDiv : "div"}>
        <Decoration theme={theme}>
          <Display
            {...image[0]}
            theme={theme}
            className={!image[1] ? "only" : ""}
          />
          {image[1] && <Mobile {...image[1]} theme={theme} />}
        </Decoration>
      </Div>
    </Wrapper>
  );
};

export default DisplayWithOverflow;
