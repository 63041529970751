import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import HeroCTA from "../HeroCTA";
import HeroTwoColumns from "../HeroTwoColumns";
import { black1, gray4, white } from "../styles/Colors";
import DisplayWithOverflow from "../DisplayWithOverflow";
import PagePadding from "../styles/PagePadding";
import HeroContentSnippet from "../HeroContentSnippet";
import TextHighlight from "../styles/TextHighlight";
import BrandsWhoWeWorkWith from "../brands/BrandsWhoWeWorkWith";
import HeroMetricsThree from "../HeroMetricsThree";
import HeroTestimonial from "../HeroTestimonial";
import { Headline32 } from "../styles/Headings";
import HeroBlueCTA from "../HeroBlueCTA";
import AnimatedLineSvg from "../AnimatedLineSvg";
import DecorationToBottom from "../snippets/DecorationToBottom";
import StackedPhonesAnimation from "../StackedPhonesAnimation";

const BrandsIndexDefer = () => {
  const images = useStaticQuery(graphql`
    query {
      media: file(relativePath: { eq: "51-stackmedia-media.png" }) {
        childImageSharp {
          fluid(quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      desktop: file(relativePath: { eq: "51-stackmedia-desktop.png" }) {
        childImageSharp {
          fluid(quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      stackMedia: file(relativePath: { eq: "51-stackmedia-media.png" }) {
        childImageSharp {
          fluid( quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      stackDesktop: file(relativePath: { eq: "51-stackmedia-desktop.png" }) {
        childImageSharp {
          fluid( quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      logoTestimonial: file(
        relativePath: { eq: "52-testimonial-rosetta-stone.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      desktopTestimonial: file(
        relativePath: { eq: "52-testimonial-brands-desktop.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      mobileTestimonial: file(
        relativePath: { eq: "52-testimonial-brands-media.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      desktopMetrics: file(relativePath: { eq: "53-brands-metric.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      mobileMetrics: file(relativePath: { eq: "53-brands-metric-media.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  return (
    <>
      <HeroCTA title="Ways we can" outline="Partner" />

      <HeroTwoColumns
        noPadding={true}
        reverse={true}
        background={black1}
        link={true}
      >
        <StackedPhonesAnimation />

        <PagePadding>
          <HeroContentSnippet
            color={white}
            title="Shops"
            subTitle="Commerce"
            link={{ to: "/brands/shops/" }}
            buttonVariant="tint"
          >
            <p>
              Your products in hundreds of{" "}
              <TextHighlight theme={{ color: white, background: black1 }}>
                publisher-branded
              </TextHighlight>{" "}
              marketplaces. Product storytelling where it counts.
            </p>
          </HeroContentSnippet>
        </PagePadding>
      </HeroTwoColumns>

      <HeroTwoColumns background={gray4}>
        <PagePadding>
          <HeroContentSnippet
            title="Stackmedia"
            subTitle="Advertising"
            link={{ to: "/brands/stack-media/" }}
          >
            Drive users{" "}
            <TextHighlight theme={{ background: gray4 }}>
              straight to your site
            </TextHighlight>
            .
            <br />
            Performance-based sponsored content at scale.
          </HeroContentSnippet>
        </PagePadding>
        <div>
          <DisplayWithOverflow
            theme={{
              mediaCss:
                "width: 100%; max-width: 100% !important; max-height: 100% !important;"
            }}
            image={[
              { fluid: images.stackDesktop.childImageSharp.fluid },
              { fluid: images.stackMedia.childImageSharp.fluid }
            ]}
          />
        </div>
      </HeroTwoColumns>

      <HeroTwoColumns paddingMedia="0 0 2px" padding="padding: 0 0 160px;">
        <AnimatedLineSvg duration={850} triggerOffset={350}>
          <DecorationToBottom />
        </AnimatedLineSvg>

        <PagePadding>
          <BrandsWhoWeWorkWith />
        </PagePadding>
      </HeroTwoColumns>

      <HeroMetricsThree
        backgroundMedia={images.mobileMetrics}
        backgroundDesktop={images.desktopMetrics}
        content={[
          {
            number: "10",
            suffix: "M+",
            description: "Avg. Total Shop <br/> Impressions per Month"
          },
          {
            number: "9",
            suffix: "M",
            description: "Registered <br/> Shoppers"
          },
          {
            number: "32",
            suffix: "K+",
            description: "Avg. Number of <br/> Impressions per Product"
          }
        ]}
      />

      <HeroTestimonial
        media={images}
        author="Andy Mead  |  BD Director @ Rosetta Stone"
      >
        <Headline32 theme={{ margins: "18px 0 39px", color: white }}>
          Rosetta Stone has partnered with Stack Commerce since 2016 on a
          regular basis. We have enjoyed some spectacular results as well as
          seen some “test and learn” opportunities.
        </Headline32>
        <Headline32 theme={{ margins: "18px 0", color: white }}>
          We continue to work together to build promotional opportunities that
          work for both of us.
        </Headline32>
      </HeroTestimonial>

      <HeroBlueCTA
        title="LET’S SELL"
        outline="TOGETHER"
        description="See how Stack can help supercharge your revenue and grow your brand."
        link={{ to: "/contact/?smopt=brand", state: { from: "brand" }, as: Link }}
        linkLabel="Get A Demo"
      />
    </>
  );
};

export default BrandsIndexDefer;
