import React from "react";
import styled from "styled-components";
import { Headline80 } from "./styles/Headings";
import { Eyebrows16 } from "./styles/Eyebrows";
import { textBlack1 } from "./styles/Colors";
import { mediaMedium } from "./styles/variables";
import { times } from "lodash";

const Wrapper = styled.div`
  position: relative;
  ${(props) => props.noBreak && "min-width: max-content;"}
`;

const Subtitle = styled(Eyebrows16)`
  margin-bottom: 26px;
  position: absolute;
  left: 0;
  top: -22px;

  @media (${mediaMedium}) {
    top: -47px;
  }
`;

const Trail = styled(Headline80)`
  margin: -9px 0;
  line-height: 1;
  opacity: 0.07;
  user-select: none;
  outline: none;
  speak: none;
  pointer-events: none;
  color: ${(props) => props.color ?? textBlack1};
`;

const TitleWrapper = styled.div`
  position: relative;
`;

const Title = styled(Headline80)`
  p {
    margin: 0;
  }

  @media (${mediaMedium}) {
    margin: ${(props) => props.marginTitle ?? "0"};
  }
`;

const TitleWithTrail = ({
  title,
  noTrail,
  color,
  subTitle,
  marginTitle,
  noBreak
}) => {
  return (
    <Wrapper noBreak={noBreak}>
      {!noTrail && (
        <>
          {times(4, (index) => (
            <Trail
              key={index}
              color={color}
              as="span"
              aria-hidden="true"
              aria-disabled="true"
            >
              {title}
            </Trail>
          ))}
        </>
      )}

      <TitleWrapper>
        {subTitle && (
          <Subtitle theme={{ color: color ?? null }}>{subTitle}</Subtitle>
        )}
        <Title marginTitle={marginTitle} theme={{ color: color ?? null }}>
          {title}
        </Title>
      </TitleWrapper>
    </Wrapper>
  );
};

export default TitleWithTrail;
