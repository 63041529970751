import React from "react";
import { useInView } from "react-intersection-observer";
import { FadeInBottom } from "./styles/animations";

const FadeInDiv = ({
  children,
  triggerOnce = true,
  translateFrom,
  threshold = [0.3, 0.5, 0.75]
}) => {
  const { ref, inView } = useInView({
    threshold: threshold,
    delay: 30,
    triggerOnce
  });

  return (
    <FadeInBottom translateFrom={translateFrom} ref={ref} active={inView}>
      {children}
    </FadeInBottom>
  );
};

export default FadeInDiv;
