import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import Image from "./Image";
import { mediaMedium } from "./styles/variables";
import { useInView } from "react-intersection-observer";
import { inject, observer } from "mobx-react";

const Container = styled.div`
  position: relative;
  ${(props) => (props.staticWrapper ? "height: 100%;" : "")}
`;

const Wrapper = styled.div`
  width: 110%;
  overflow: ${(props) => (props.staticWrapper ? "visible" : "hidden")};
  height: calc(100vw + 200px);
  left: 10%;
  pointer-events: none;

  @media (max-width: 320px) {
    height: calc(100vw + 100px);
  }

  @media (${mediaMedium}) {
    position: ${(props) => (props.staticWrapper ? "absolute" : "absolute")};
    ${(props) => (props.staticWrapper ? "" : "left: -10%;")}
    ${(props) => (props.staticWrapper ? "" : "top: -2%;")}
    height: ${(
      props
    ) => (props.staticWrapper ? "100%" : "100vw;")};
    ${(props) => (props.staticWrapper ? "" : "max-height: calc(732px + 2%);")}
  }

  @media (min-width: 1034px) {
    ${(props) => (props.staticWrapper ? "" : "top: -12%;")}
    ${(props) => (props.staticWrapper ? "" : "max-height: calc(732px + 12%);")}
  }
`;

const Display = styled.div`
  width: 110%;
  margin-top: -52%;
  position: relative;
  z-index: 5;
  transition: cubic-bezier(0.46, 0.03, 0.52, 0.96) 0.7s;
  transition-property: transform;
  transform: translateY(${(props) => (props.active ? 0 : 30)}%);
  transition-delay: ${(props) => (props.active ? `${props.delay}ms` : `1s`)};

  @media (${mediaMedium}) {
    margin-top: ${(props) => (props.staticWrapper ? "-35%" : "-42%")};
    width: ${(props) => (props.staticWrapper ? "100%" : "110%")};
  }

  &:nth-child(1) {
    margin-top: 0;
  }

  &:nth-child(2) {
    z-index: 4;
  }
  &:nth-child(3) {
    z-index: 3;
  }
  &:nth-child(4) {
    z-index: 2;
  }
  &:nth-child(5) {
    z-index: 1;
  }
`;

const Threshold = [0.2, 0.3, 0.4, 1];
const StackedPhonesAnimation = ({ staticWrapper, store: { ui } }) => {
  const images = useStaticQuery(graphql`
    query {
      allFile(
        filter: { name: { regex: "/50-brands-commerce-[1-5]/" } }
        sort: { fields: name, order: ASC }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
      }
    }
  `);

  const { ref, inView } = useInView({
    threshold: Threshold,
    delay: 30,
    triggerOnce: true
  });

  const activeAnimation = inView;

  return (
    <Container staticWrapper={staticWrapper}>
      <Wrapper staticWrapper={staticWrapper} ref={ref}>
        {images.allFile.edges.map((item, key) => {
          if (staticWrapper && key > 3) {
            return "";
          }
          return (
            <Display
              staticWrapper={staticWrapper}
              key={"phone-image-" + key}
              delay={key * 400}
              active={activeAnimation}
            >
              <Image fluid={item.node.childImageSharp.fluid} />
            </Display>
          );
        })}
      </Wrapper>
    </Container>
  );
};

export default inject(["store"])(observer(StackedPhonesAnimation));
