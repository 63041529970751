import React from "react";
import styled from "styled-components";
import OutlinedText from "../styles/OutlinedText";
import CTALink from "../styles/CTALink";
import { Headline80, Headline40 } from "../styles/Headings";
import { Body20 } from "../styles/BodyTypes";
import { mediaMedium } from "../styles/variables";

const Title = styled(Headline80)`
  margin: 0 0 64px;

  @media (${mediaMedium}) {
    margin: 0 0 90px;
  }
`;

const Wrapper = styled.div`
  padding: 73px 0 84px;

  @media (${mediaMedium}) {
    padding: 160px 0;
  }
`;

const SnippetWrapper = styled.div`
  margin-bottom: 50px;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (${mediaMedium}) {
    margin-bottom: 80px;
  }
`;

const Snippet = ({ title, link, content }) => {
  return (
    <SnippetWrapper>
      <Headline40 theme={{ margins: "0 0 12px" }}>{title}</Headline40>
      <Body20 theme={{ margins: "0" }}>
        {content}
        {link && (
          <>
            <br /> <CTALink attrs={link}>Let’s talk.</CTALink>
          </>
        )}
      </Body20>
    </SnippetWrapper>
  );
};

const data = [
  {
    title: "App Developers & Designers",
    content:
      "If you develop innovative apps and software, or design assets like stock images and vectors, we want to meet you."
  },
  {
    title: "Online Instructors & authors",
    content:
      "From coding to finance to marketing to painting, we work with hundreds of passionate online course instructors and ebook authors from around the world."
  },
  {
    title: "Makers & Distributors",
    content:
      "We partner with artisan makers and large merchants alike, selling everything from handmade chef knives to refurbished iPads to smartwatches. "
  },
  {
    title: "Not On This List?",
    content: "We’re testing new product types every day.",
    link: { to: "/contact/?smopt=brand", state: { from: "brands" } }
  }
];

const BrandsWhoWeWorkWith = () => {
  return (
    <Wrapper>
      <Title>
        Who We <br />
        <OutlinedText as={"span"} component={"span"}>
          Work With
        </OutlinedText>
      </Title>
      {data.map((item, key) => (
        <Snippet key={`feature` + key} {...item} />
      ))}
    </Wrapper>
  );
};

export default BrandsWhoWeWorkWith;
