import styled from "styled-components";
import { mediaMedium } from "./variables";

const PagePadding = styled.div`
  padding-left: 20px;
  padding-right: 20px;

  @media (${mediaMedium}) {
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: ${(props) => props.paddingBottom ?? 0};
  }
`;

export default PagePadding;
